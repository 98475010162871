.textInputWrapper {
    position: fixed;
    display: flex;
    bottom: 0;
    left: 0;
    height: 5rem;
    width: 100%;
    padding: 0 5rem;
    gap: 1rem;
    justify-content: center;
    background-color: rgb(32, 32, 32);
    align-items: center;
}

.textInput {
    height: 3rem;
    border-radius: 100000px;
    transition: all 0.3s;
    width: 5rem;
    outline: none;
    padding: 1rem;
    border: 1px solid rgba(128, 128, 128, 0.664);
    flex-grow: 1;
    max-width: 90rem;
    background-color: #7e7e7e;
}

.textInput:focus {
    border: 1px solid rgb(0, 0, 0);
    background-color: #ffffff;
}

.sendButton {
    background-image: linear-gradient(45deg,#00F260,#0575E6);
    filter: grayscale(0.4);
    text-shadow: 0 0 10px rgb(0, 0, 0);
    color: white;
    border: none;
    transition: all 0.3s;
    height: 2.5rem;
    width: 5rem;
    border-radius: 1000000px;
    font-size: 1.05em;
}

.sendButton:hover {
    filter: grayscale(0);
    cursor: pointer;
    transform: scale(1.05);
}

@media (max-width: 756px) {
    .textInputWrapper {
        padding: 0 1rem;
    }
}