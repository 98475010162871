.textContent {
    display: inline-flex;
    width: fit-content;
    height: fit-content;
    background-color: rgb(82, 82, 82);
    border-radius: 5px;
    padding: 1rem;
    align-items: center;
    transition: background-color 0.4s ease-out, transform 0.4s ease-out;
    max-width: calc(100% - 2rem);
}

.button {
    background-color: #616161;
    max-height: 2rem;
    padding: 0.5rem;
    border: none;
    border-radius: 20px;
    transition: border-radius 0.4s ease-out, background-color 0.4s ease-out, color 0.4s ease-out;
    cursor: pointer;
}

.deleteSvg {
    fill: #480000;
    transition: all 0.4s ease-in-out;
    width: 1rem;
    height: 1rem;
}

.copySvg {
    fill: #c5c5c5;
    transition: all 0.4s ease-in-out;
    width: 1rem;
    height: 1rem;
}

.textContent:hover .deleteSvg {
    fill: #b30000;
    border-radius: 0.2rem;
}

.textContent:hover .copySvg {
    fill: #ffffff;
    border-radius: 0.2rem;
}

.textContent:hover .button {
    border-radius: 0.5rem;
    background-color: #7c7c7c;
}

.textContent:hover {
    background-color: #565656;
    transform: scale(1.05);
    box-shadow: 0 0 0.5rem 0.03rem rgba(155, 155, 155, 0.3);
}

.textfield {
    padding: 1rem;
    overflow-y: auto;
}
