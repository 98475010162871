.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20rem;
    height: 30rem;
    background-color: rgb(32, 32, 32);
    border-radius: 1rem;
    gap: 2rem;
}

.input {
    color: white;
    height: 1rem;
    border-radius: 100000px;
    transition: all 0.3s;
    outline: none;
    padding: 1rem;
    background-color: rgb(127, 127, 127);
    border: none;
}

.button {
    background-image: linear-gradient(45deg,#00F260,#0575E6);
    text-shadow: 0 0 10px rgba(0,0,0,0.2);
    color: white;
    border: none;
    transition: all 0.3s;
    height: 2.5rem;
    width: 10rem;
    border-radius: 1000000px;
    font-size: 1.05em;
    cursor: pointer;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}
