.feedWrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 5rem;
    top: 2.5rem;
    gap: 1rem;
    padding: 1rem;
    overflow-y: scroll;
    padding-top: 2rem;
    align-content: flex-start;
}

.infoWrapper {
    width: 100%;
    text-align: center;
    position: fixed;
    top: 5rem;
    z-index: 2;
}

.button {
    background-color: transparent;
    border: none;
    transition: transform .5s;
}

.button:hover {
    cursor: pointer;
    transform: rotate(-90deg);
}

.reloadSvg {
   fill: blue;
    height: 3rem;
    width: 3rem;
}